import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import {
  PaginationCustom,
  PanelHeader,
  Spinner,
  TableCustom
} from '../../components';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { all as allProviders } from '../../helpers/actions/providers';
import { adminNotes } from '../../helpers/actions/projects';
import client from '../../helpers/api/projects';
import { dateFormatter, supervisorName } from '../../helpers/formatters';
import { noteTypes } from '../../helpers/nomenclators';
import { getUrlParam } from '../../helpers/urlParams';
import { all } from '../../helpers/actions/users';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const dates = [
  {
    name: 'Last 7 Days',
    value: dateFormatter(moment().subtract(7, 'days'), dateFormat)
  },
  {
    name: 'Last Month',
    value: dateFormatter(moment().subtract(1, 'months'), dateFormat)
  },
  {
    name: 'Last 3 Months',
    value: dateFormatter(moment().subtract(3, 'months'), dateFormat)
  },
  {
    name: 'Last 6 Months',
    value: dateFormatter(moment().subtract(6, 'months'), dateFormat)
  },
  {
    name: 'Last Year',
    value: dateFormatter(moment().subtract(1, 'years'), dateFormat)
  }
];

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      problems: [],
      count: 0,
      page: 1,
      limit: 18,
      type: 'Problem',
      completed: false,
      provider: '',
      providers: [],
      supervisor: '',
      supervisors: [],
      date: dateFormatter(moment().subtract(7, 'days'), dateFormat)
    };
  }

  load() {
    this.setState({ loading: true, problems: [] });

    let {
        page,
        limit,
        type,
        completed,
        provider,
        supervisor,
        date
      } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(
      adminNotes({ limit, offset, type, completed, provider, supervisor, date })
    )
      .then(result => {
        this.setState({
          problems: result.data,
          ...result.meta,
          provider,
          supervisor,
          date,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  }

  componentWillMount() {
    let type = getUrlParam('type', '');
    let provider = getUrlParam('provider', '');
    let supervisor = getUrlParam('supervisor', '');
    let date = getUrlParam('date', null);

    this.setState(ps => ({
      ...ps,
      type,
      provider,
      supervisor,
      date: date
        ? date
        : dateFormatter(moment().subtract(7, 'days'), dateFormat)
    }));
  }

  componentDidMount() {
    this.setState({ loading: true });

    let {
        page,
        limit,
        type,
        completed,
        provider,
        supervisor,
        date
      } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    Promise.all([
      dispatch(
        adminNotes({
          limit,
          offset,
          type,
          completed,
          provider,
          supervisor,
          date
        })
      ),
      dispatch(allProviders({ limit: 100 })),
      dispatch(all({ limit: 1000, role: 'supervisor' }))
    ])
      .then(([{ meta, data }, providers, supervisors]) => {
        this.setState({
          problems: data,
          ...meta,
          provider,
          providers: providers.data,
          supervisors: supervisors.data,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  updateAllNotes() {
    client
      .updateAllNotes()
      .then()
      .catch(() => {});
  }

  getNotesData() {
    let { problems } = this.state,
      { t } = this.props;

    return problems.map(problem => {
      return {
        className: '',
        data: [
          {
            className: '',
            fixed: true,
            value: (
              <Link to={`/projects/${problem.project.id}`}>
                {problem.project.name}
              </Link>
            )
          },
          { className: '', fixed: true, value: problem.title },
          { className: '', fixed: false, value: t(problem.type) },
          {
            className: 'text-center',
            fixed: false,
            value: dateFormatter(problem.createdAt, 'llll')
          },
          {
            className: 'text-justify',
            fixed: false,
            properties: { width: '40%' },
            value: <div dangerouslySetInnerHTML={{ __html: problem.text }} />
          }
        ]
      };
    });
  }

  render() {
    let {
        loading,
        page,
        limit,
        count,
        providers,
        supervisors,
        supervisor,
        provider,
        type,
        date
      } = this.state,
      { t } = this.props;
    let rows = this.getNotesData();

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardHeader>
              <Form>
                <Row>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={noteTypes.map(p => ({
                          label: t(p),
                          value: p
                        }))}
                        value={type ? type : ''}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              type: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Provider</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={providers.map(p => ({
                          label: p.name,
                          value: p._id
                        }))}
                        value={provider}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              provider: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Supervisor</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={supervisors.map(sup => ({
                          label: supervisorName(sup),
                          value: sup._id
                        }))}
                        value={supervisor}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              supervisor: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <Label>
                        <Trans>Period</Trans>
                      </Label>
                      <Select
                        className="primary"
                        clearable={false}
                        options={dates.map(d => ({
                          label: d.name,
                          value: d.value
                        }))}
                        value={date}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              date: event ? event.value : ''
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4}>
                    <Link
                      to={`/projects/reports/notes?type=${type}&provider=${provider}&supervisor=${supervisor}&date=${date}`}
                      className="btn btn-info float-right"
                    >
                      <Trans>Report</Trans>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </CardHeader>
            <CardBody>
              <TableCustom
                minWidth={767}
                className={'devi-item-material-table'}
                accordion={true}
                heads={[
                  { value: t('Project') },
                  { value: t('Title') },
                  { value: t('Type') },
                  { value: t('Date Create'), className: 'text-center' },
                  { value: t('Description'), className: 'text-justify' }
                ]}
                rows={rows}
                emptyMessage={t('No problems found')}
              />
            </CardBody>
          </Card>
          <Row>
            <Col xs={12} className={'text-center'}>
              <PaginationCustom
                className="display-flex justify-content-center"
                page={page}
                count={count}
                maxPerPage={limit}
                onPageChange={page =>
                  this.setState({ page }, () => this.load())
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Notes));
