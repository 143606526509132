import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { PanelHeader, Spinner, GoogleMapLibrary } from '../../../components';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Datetime from 'react-datetime';
import moment from 'moment';
import _ from 'lodash';
import {
  one,
  save,
  countDevisByProject
} from '../../../helpers/actions/projects';
import { all as allProviders } from '../../../helpers/actions/providers';
import { all, save as saveUser, photo } from '../../../helpers/actions/users';
import { supervisorName } from '../../../helpers/formatters';
import {
  projectCategories,
  projectType,
  clientOrigin
} from '../../../helpers/nomenclators';
import NavbarProject from '../NavbarActions/NavbarProject';
import defaultAvatar from '../../../assets/img/default-avatar.png';

export default connect()(
  translate('translations-fr')(
    class extends Component {
      constructor(props) {
        super(props);
        this.state = {
          loading: false,
          project: {
            startDate: moment(),
            endDate: moment()
          },
          user: {
            phone: {},
            client: {}
          },
          countDevis: 0,
          step: 0,
          file: null,
          showGallery: false,
          providers: [],
          supervisors: []
        };
      }

      componentWillMount() {
        this.setState({ loading: true });

        let { dispatch, match } = this.props;
        let promises = [
          dispatch(allProviders({ limit: 100 })),
          dispatch(all({ limit: 1000, role: 'supervisor' }))
        ];

        if (match.params.id) {
          //edit project
          promises.push(
            dispatch(
              one(match.params.id, {
                include: ['client', 'provider', 'devis', 'devisBase'],
                fields: [
                  'name',
                  'status',
                  'provider',
                  'supervisors',
                  'type',
                  'surface',
                  'address',
                  'description',
                  'startDate',
                  'endDate',
                  'client',
                  'devis',
                  'devisBase',
                  'children'
                ]
              })
            )
          );
          promises.push(dispatch(countDevisByProject(match.params.id)));
        }

        Promise.all(promises)
          .then(([providers, supervisors, project, countDevis]) => {
            console.log(project);
            if (project && project._id) {
              //edit project
              let client =
                project.client && project.client._id
                  ? project.client
                  : {
                      phone: {},
                      client: {}
                    };

              if (!client.phone) client.phone = {};
              if (!client.client) client.client = {};

              this.setState({
                providers: providers.data,
                supervisors: supervisors.data,
                project,
                user: client,
                countDevis:
                  countDevis && countDevis.total ? countDevis.total : 0,
                loading: false
              });
            } else {
              //create new project
              this.setState({
                providers: providers.data,
                supervisors: supervisors.data,
                loading: false
              });
            }
          })
          .catch(() => this.setState({ loading: false }));
      }

      onChangeProject(name, nameValid, value, valid) {
        let { project } = this.state;

        project[name] = value;

        this.setState({
          project,
          [nameValid]: valid ? 'has-success' : 'has-danger'
        });
      }

      onChangeClient(dataType, name, nameValid, value, valid) {
        let { user } = this.state;

        if (dataType) {
          user[dataType][name] = value;
        } else {
          user[name] = value;
        }

        this.setState({
          user,
          [nameValid]: valid ? 'has-success' : 'has-danger'
        });
      }

      handleImageChange(file) {
        let { user } = this.state;
        let reader = new FileReader();

        reader.onloadend = () => {
          user.photoURL = reader.result;
          this.setState(ps => ({ ...ps, user, file: file }));
        };
        reader.readAsDataURL(file);
      }

      handleClick() {
        let input = document.createElement('input');

        input.type = 'file';
        input.onchange = e => {
          e.preventDefault();
          this.handleImageChange(e.target.files[0]);
        };
        input.click();
      }

      onPrevious() {
        let { step } = this.state;

        this.setState({ step: step - 1 });
      }

      onNextStep() {
        let { step, project, user, supervisors } = this.state,
          { dispatch } = this.props;

        switch (step) {
          case 0:
            if (this.validateProject()) {
              this.setState({ step: 1 });
            }
            break;
          case 1:
            if (this.validateClientEmail()) {
              dispatch(all({ limit: 1, search: user.email }))
                .then(res => {
                  if (res && res.data && res.data.length) {
                    let data = res.data[0];

                    if (data.roles && data.roles.length) {
                      if (!_.find(data.roles, t => t === 'client')) {
                        data.roles.push('client');
                      }
                    } else {
                      data.roles = ['client'];
                    }
                    if (!data.phone) data.phone = {};
                    if (!data.client) data.client = {};

                    this.setState({ user: data, step: 2 });
                  } else {
                    user.roles = ['client'];
                    user.address = project.address;
                    this.setState({ user, step: 2 });
                  }
                })
                .catch(() => {
                  user.roles = ['client'];
                  user.address = project.address;
                  this.setState({ user, step: 2 });
                });
            }
            break;
          case 2:
            if (this.validateClient()) {
              if (!project._id) {
                //create default project name
                if (
                  user.displayName &&
                  project.category &&
                  project.supervisors &&
                  project.supervisors.length
                ) {
                  let supervisor = supervisors.find(
                    t => t._id === project.supervisors[0]
                  );
                  if (supervisor && supervisor.displayName)
                    project.name = `${user.displayName} - ${project.category} - ${supervisor.displayName}`;
                  else project.name = '';
                } else project.name = '';
              }

              this.setState({ project, step: 3 });
            }
            break;
          default:
            this.setState({ step: 0 });
        }
      }

      onSaveProject() {
        if (this.validateProjectEnd()) {
          let { project, user, file } = this.state,
            { dispatch, history } = this.props;
          this.setState({ loading: true });

          let clientAddress = this.getGeocodeByAddress(user.address);
          user.lat = clientAddress.lat;
          user.lng = clientAddress.lng;

          let projectAddress = this.getGeocodeByAddress(project.address);
          project.lat = projectAddress.lat;
          project.lng = projectAddress.lng;

          //save client
          dispatch(saveUser(user))
            .then(userRes => {
              let entity = {
                name: project.name || '',
                client: userRes && userRes._id ? userRes._id : null,
                supervisors: project.supervisors || null,
                provider: project.provider || null,
                type: project.type || null,
                surface: project.surface || null,
                address: project.address || '',
                lat: project.lat,
                lng: project.lng,
                description: project.description || '',
                startDate: project.startDate || null,
                endDate: project.endDate || null,
                parent: null
              };
              if (project._id) entity['_id'] = project._id;

              let promises = [dispatch(save(entity))];

              if (userRes._id && file) {
                promises.push(dispatch(photo(userRes._id, file)));
              }

              Promise.all(promises)
                .then(([projectRes, userPhoto]) => {
                  history.push(`/projects/${projectRes._id}`);
                })
                .catch(() => this.setState({ loading: false }));
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        }
      }

      async getGeocodeByAddress(address) {
        if (address && address !== '') {
          await geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
              return { lat, lng };
            })
            .catch(() => {
              return { lat: null, lng: null };
            });
        } else {
          return { lat: null, lng: null };
        }
      }

      validateProject() {
        let { project } = this.state;
        let address = this.address;
        let surface = this.surface;
        let startDate = this.startDate;
        let endDate = this.endDate;

        this.setState({
          typeValid: project.type ? 'has-success' : 'has-danger',
          categoryValid: 'has-success',
          [surface.attributes.getNamedItem('namevalid').value]: surface.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [address.attributes.getNamedItem('namevalid').value]: address.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [startDate.attributes.getNamedItem('namevalid').value]: startDate
            .validity.valid
            ? 'has-success'
            : 'has-danger',
          [endDate.attributes.getNamedItem('namevalid').value]: endDate.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          providerValid: project.type ? 'has-success' : 'has-danger',
          supervisorValid: 'has-success'
        });

        return (
          project.type &&
          surface.validity.valid &&
          address.validity.valid &&
          startDate.validity.valid &&
          endDate.validity.valid &&
          project.provider
        );
      }

      validateClientEmail() {
        let email = this.email;

        this.setState({
          [email.attributes.getNamedItem('namevalid').value]: email.validity
            .valid
            ? 'has-success'
            : 'has-danger'
        });

        return email.validity.valid;
      }

      validateClient() {
        let displayName = this.displayName;
        let email = this.email;
        let address = this.address;
        let mobile = this.mobile;
        let work = this.work;
        let landline = this.landline;

        this.setState({
          [displayName.attributes.getNamedItem('namevalid').value]: displayName
            .validity.valid
            ? 'has-success'
            : 'has-danger',
          [email.attributes.getNamedItem('namevalid').value]: email.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [address.attributes.getNamedItem('namevalid').value]: address.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
            ? 'has-success'
            : 'has-danger',
          [landline.attributes.getNamedItem('namevalid').value]: landline
            .validity.valid
            ? 'has-success'
            : 'has-danger'
        });

        return (
          displayName.validity.valid &&
          email.validity.valid &&
          address.validity.valid &&
          mobile.validity.valid &&
          work.validity.valid &&
          landline.validity.valid
        );
      }

      validateProjectEnd() {
        let name = this.name;
        let description = this.description;

        this.setState({
          [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
            ? 'has-success'
            : 'has-danger',
          [description.attributes.getNamedItem('namevalid').value]: description
            .validity.valid
            ? 'has-success'
            : 'has-danger'
        });

        return name.validity.valid && description.validity.valid;
      }

      render() {
        let {
            loading,
            step,
            project,
            user,
            countDevis,
            providers,
            supervisors
          } = this.state,
          { t } = this.props;

        return (
          <div>
            <PanelHeader size="sm" />
            <div className="content">
              <NavbarProject project={project} history={this.props.history} />
              {loading ? <Spinner inside={true} /> : null}
              <Col>
                <Row className={'text-center'}>
                  <Col xs={'3'}>
                    <p
                      className={`${
                        step === 0 ? 'text-md font-weight-bold' : 'text-muted'
                      }`}
                    >
                      <span className={'rounded-circle step-number mr-1'}>
                        1
                      </span>
                      <Trans>Project Data</Trans>
                    </p>
                  </Col>
                  <Col xs={'3'}>
                    <p
                      className={`${
                        step === 1 ? 'text-md font-weight-bold' : 'text-muted'
                      }`}
                    >
                      <span className={'rounded-circle step-number mr-1'}>
                        2
                      </span>
                      <Trans>Client Email</Trans>
                    </p>
                  </Col>
                  <Col xs={'3'}>
                    <p
                      className={`${
                        step === 2 ? 'text-md font-weight-bold' : 'text-muted'
                      }`}
                    >
                      <span className={'rounded-circle step-number mr-1'}>
                        3
                      </span>
                      <Trans>Client Data</Trans>
                    </p>
                  </Col>
                  <Col xs={'3'}>
                    <p
                      className={`${
                        step === 3 ? 'text-md font-weight-bold' : 'text-muted'
                      }`}
                    >
                      <span className={'rounded-circle step-number mr-1'}>
                        4
                      </span>
                      <Trans>Project</Trans> <Trans>Name</Trans>
                    </p>
                  </Col>
                </Row>
              </Col>

              {step === 0 ? (
                <Card>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.typeValid}
                          >
                            <Label>
                              <Trans>Type</Trans>
                            </Label>
                            <Select
                              className="primary"
                              clearable={false}
                              closeOnSelect={true}
                              disabled={
                                project._id &&
                                (countDevis > 0 ||
                                  (project.type === 'compound' &&
                                    project.children &&
                                    project.children.length > 0))
                              }
                              options={projectType.map(pt => ({
                                label: t(pt),
                                value: pt
                              }))}
                              value={project.type || null}
                              required={true}
                              onChange={event => {
                                this.onChangeProject(
                                  'type',
                                  'typeValid',
                                  event ? event.value : null,
                                  !!event
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.categoryValid}
                          >
                            <Label>
                              <Trans>Category</Trans>
                            </Label>
                            <CreatableSelect
                              className="primary"
                              closeOnSelect={true}
                              value={project.category || null}
                              options={projectCategories.map(pc => ({
                                label: pc,
                                value: pc
                              }))}
                              onNewOptionClick={event => {
                                projectCategories.push(event.value);
                                this.onChangeProject(
                                  'category',
                                  'categoryValid',
                                  event ? event.value : null,
                                  !!event
                                );
                              }}
                              onChange={event =>
                                this.onChangeProject(
                                  'category',
                                  'categoryValid',
                                  event ? event.value : null,
                                  !!event
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.surfaceValid}
                          >
                            <Label>
                              <Trans>Surface</Trans> (m <sup>2</sup>)
                            </Label>
                            <Input
                              type="number"
                              innerRef={node => (this.surface = node)}
                              namevalid="surfaceValid"
                              value={project.surface || ''}
                              step={0.1}
                              min={1}
                              required="required"
                              onChange={event =>
                                this.onChangeProject(
                                  'surface',
                                  'surfaceValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup
                            className={'has-label ' + this.state.addressValid}
                          >
                            <Label>
                              <Trans>Address</Trans>
                            </Label>
                            <GoogleMapLibrary>
                              <PlacesAutocomplete
                                value={project.address || ''}
                                onChange={address =>
                                  this.onChangeProject(
                                    'address',
                                    'addressValid',
                                    address,
                                    address !== null && address !== ''
                                  )
                                }
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps
                                }) => (
                                  <div>
                                    <input
                                      ref={node => (this.address = node)}
                                      required="required"
                                      {...getInputProps({
                                        className: 'form-control',
                                        namevalid: 'addressValid'
                                      })}
                                    />
                                    <ul
                                      className={
                                        'dropdown-menu inner ' +
                                        (suggestions.length === 0 ? '' : 'show')
                                      }
                                    >
                                      {suggestions.map(suggestion => (
                                        <li
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                        >
                                          <a
                                            href="/#"
                                            className="dropdown-item"
                                          >
                                            <span className="text">
                                              {suggestion.description}
                                            </span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </GoogleMapLibrary>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.startDateValid}
                          >
                            <Label>
                              <Trans>Start Date</Trans>
                            </Label>
                            <Datetime
                              locale={'fr'}
                              value={moment(project.startDate)}
                              inputProps={{
                                ref: node => (this.startDate = node),
                                namevalid: 'startDateValid',
                                className: 'form-control datetime-read-only',
                                readOnly: true
                              }}
                              onChange={moment =>
                                this.onChangeProject(
                                  'startDate',
                                  'startDateValid',
                                  moment,
                                  !!moment
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.endDateValid}
                          >
                            <Label>
                              <Trans>End Date</Trans>
                            </Label>
                            <Datetime
                              isValidDate={c => {
                                let end = moment(c);
                                let start = moment(project.startDate);
                                return end.isSameOrAfter(
                                  moment(start, 'DD-MM-YYYY')
                                );
                              }}
                              locale={'fr'}
                              value={moment(project.endDate)}
                              inputProps={{
                                ref: node => (this.endDate = node),
                                namevalid: 'endDateValid',
                                className: 'form-control datetime-read-only',
                                readOnly: true
                              }}
                              onChange={moment =>
                                this.onChangeProject(
                                  'endDate',
                                  'endDateValid',
                                  moment,
                                  !!moment
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup
                            className={'has-label ' + this.state.providerValid}
                          >
                            <Label>
                              <Trans>Provider</Trans>
                            </Label>
                            <Select
                              className="primary"
                              clearable={false}
                              options={providers.map(p => ({
                                label: p.name,
                                value: p._id
                              }))}
                              value={
                                project.provider ? project.provider._id : null
                              }
                              required={true}
                              disabled={!!project._id}
                              onChange={event => {
                                this.onChangeProject(
                                  'provider',
                                  'providerValid',
                                  event
                                    ? providers.find(p => p._id === event.value)
                                    : null,
                                  !!event
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.supervisorValid
                            }
                          >
                            <Label>
                              <Trans>Supervisor</Trans>
                            </Label>
                            <Select
                              className="primary"
                              multi={true}
                              closeOnSelect={false}
                              options={supervisors.map(p => ({
                                label: supervisorName(p),
                                value: p._id
                              }))}
                              value={
                                project.supervisors ? project.supervisors : null
                              }
                              onChange={event => {
                                this.onChangeProject(
                                  'supervisors',
                                  'supervisorValid',
                                  event.map(e => e.value),
                                  !!event.length
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              ) : null}

              {step === 1 ? (
                <Card>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className={'has-label ' + this.state.emailValid}
                          >
                            <Label>
                              <Trans>Client</Trans> <Trans>Email</Trans>
                            </Label>
                            <Input
                              type="email"
                              innerRef={node => (this.email = node)}
                              namevalid="emailValid"
                              value={user.email || ''}
                              required="required"
                              onChange={event =>
                                this.onChangeClient(
                                  null,
                                  'email',
                                  'emailValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              ) : null}

              {step === 2 ? (
                <Card>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col xs={12} md={3}>
                          <Col
                            xs={12}
                            className={'display-flex justify-content-center'}
                          >
                            <div
                              className="avatar-image"
                              style={{
                                backgroundImage: `url('${_.get(
                                  user,
                                  'photoURL',
                                  defaultAvatar
                                )}')`
                              }}
                            />
                          </Col>
                          <Col
                            xs={12}
                            className={'justify-content-center text-center'}
                          >
                            <Button
                              size={'sm'}
                              color={'info'}
                              round="true"
                              onClick={() => this.handleClick()}
                            >
                              <Trans>Change</Trans>
                            </Button>
                            <Button
                              size={'sm'}
                              color="info"
                              onClick={() =>
                                this.setState({ showGallery: true })
                              }
                            >
                              <Trans>Gallery</Trans>
                            </Button>
                          </Col>
                        </Col>
                        <Col xs={12} md={9}>
                          <Row>
                            <Col xs={12} md={4}>
                              <FormGroup
                                className={
                                  'has-label ' + this.state.displayNameValid
                                }
                              >
                                <Label>
                                  <Trans>Display Name</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.displayName = node)}
                                  namevalid="displayNameValid"
                                  value={user.displayName || ''}
                                  required="required"
                                  onChange={event =>
                                    this.onChangeClient(
                                      null,
                                      'displayName',
                                      'displayNameValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup
                                className={'has-label ' + this.state.emailValid}
                              >
                                <Label>
                                  <Trans>Email</Trans>
                                </Label>
                                <Input
                                  type="email"
                                  innerRef={node => (this.email = node)}
                                  namevalid="emailValid"
                                  value={user.email || ''}
                                  required="required"
                                  disabled={true}
                                  onChange={event =>
                                    this.onChangeClient(
                                      null,
                                      'email',
                                      'emailValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup
                                className={
                                  'has-label ' + this.state.originValid
                                }
                              >
                                <Label>
                                  <Trans>Origin</Trans>
                                </Label>
                                <Select
                                  className="primary"
                                  options={clientOrigin.map(c => ({
                                    label: t(c),
                                    value: c
                                  }))}
                                  value={user.client.origin || null}
                                  onChange={event =>
                                    this.onChangeClient(
                                      'client',
                                      'origin',
                                      'originValid',
                                      event ? event.value : null,
                                      !!event
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12}>
                              <FormGroup
                                className={
                                  'has-label ' + this.state.clientAddressValid
                                }
                              >
                                <Label>
                                  <Trans>Address</Trans>
                                </Label>
                                <GoogleMapLibrary>
                                  <PlacesAutocomplete
                                    value={user.address || ''}
                                    onChange={address =>
                                      this.onChangeClient(
                                        null,
                                        'address',
                                        'addressValid',
                                        address,
                                        true
                                      )
                                    }
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps
                                    }) => (
                                      <div>
                                        <input
                                          ref={node => (this.address = node)}
                                          {...getInputProps({
                                            className: 'form-control',
                                            namevalid: 'addressValid'
                                          })}
                                        />
                                        <ul
                                          className={
                                            'dropdown-menu inner ' +
                                            (suggestions.length === 0
                                              ? ''
                                              : 'show')
                                          }
                                        >
                                          {suggestions.map(suggestion => (
                                            <li
                                              {...getSuggestionItemProps(
                                                suggestion
                                              )}
                                            >
                                              <a
                                                href="/#"
                                                className="dropdown-item"
                                              >
                                                <span className="text">
                                                  {suggestion.description}
                                                </span>
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </GoogleMapLibrary>
                              </FormGroup>
                            </Col>
                            <Col>
                              <Row>
                                <Col xs={12} md={4}>
                                  <FormGroup
                                    className={
                                      'has-label ' + this.state.mobileValid
                                    }
                                  >
                                    <Label>
                                      <Trans>Mobile</Trans>
                                    </Label>
                                    <Input
                                      type="text"
                                      innerRef={node => (this.mobile = node)}
                                      namevalid="mobileValid"
                                      value={user.phone.mobile || ''}
                                      onChange={event =>
                                        this.onChangeClient(
                                          'phone',
                                          'mobile',
                                          'mobileValid',
                                          event.target.value,
                                          event.target.validity.valid
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} md={4}>
                                  <FormGroup
                                    className={
                                      'has-label ' + this.state.workValid
                                    }
                                  >
                                    <Label>
                                      <Trans>Work</Trans>
                                    </Label>
                                    <Input
                                      type="text"
                                      innerRef={node => (this.work = node)}
                                      namevalid="workValid"
                                      value={user.phone.work || ''}
                                      onChange={event =>
                                        this.onChangeClient(
                                          'phone',
                                          'work',
                                          'workValid',
                                          event.target.value,
                                          event.target.validity.valid
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} md={4}>
                                  <FormGroup
                                    className={
                                      'has-label ' + this.state.landlineValid
                                    }
                                  >
                                    <Label>
                                      <Trans>Landline</Trans>
                                    </Label>
                                    <Input
                                      type="text"
                                      innerRef={node => (this.landline = node)}
                                      namevalid="landlineValid"
                                      value={user.phone.landline || ''}
                                      onChange={event =>
                                        this.onChangeClient(
                                          'phone',
                                          'landline',
                                          'landlineValid',
                                          event.target.value,
                                          event.target.validity.valid
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              ) : null}

              {step === 3 ? (
                <Card>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className={'has-label ' + this.state.nameValid}
                          >
                            <Label>
                              <Trans>Name</Trans>
                            </Label>
                            <Input
                              type="text"
                              innerRef={node => (this.name = node)}
                              namevalid="nameValid"
                              value={project.name || ''}
                              required="required"
                              onChange={event =>
                                this.onChangeProject(
                                  'name',
                                  'nameValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.descriptionValid
                            }
                          >
                            <Label>
                              <Trans>Description</Trans>
                            </Label>
                            <Input
                              type="textarea"
                              innerRef={node => (this.description = node)}
                              namevalid="descriptionValid"
                              value={project.description || ''}
                              onChange={event => {
                                this.onChangeProject(
                                  'description',
                                  'descriptionValid',
                                  event.target.value,
                                  event.target.validity.valid
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              ) : null}

              <Card className="card-plain">
                <CardBody>
                  {step !== 0 ? (
                    <Button
                      className="float-left"
                      color="info"
                      onClick={() => this.onPrevious()}
                    >
                      <Trans>Previous</Trans>
                    </Button>
                  ) : null}

                  {step !== 3 ? (
                    <Button
                      className="float-right"
                      color="info"
                      onClick={() => this.onNextStep()}
                    >
                      <Trans>Next</Trans>
                    </Button>
                  ) : null}

                  {step === 3 ? (
                    <Button
                      className="float-right"
                      color="info"
                      onClick={() => this.onSaveProject()}
                    >
                      <Trans>Save</Trans>
                    </Button>
                  ) : null}
                </CardBody>
              </Card>
            </div>
          </div>
        );
      }
    }
  )
);
